import Checkbox from 'design/components/Checkbox/Checkbox'
import ModalCard, { IModalCard } from 'design/components/ModalCard/ModalCard'
import { rem } from 'design/utils'
import { FieldProps } from 'formik'
import React from 'react'
import { Box } from '@chakra-ui/core'

export type IModalCardCheckbox = Partial<FieldProps['field']> & IModalCard

const ModalCardCheckbox: React.FC<IModalCardCheckbox> = ({
  checked,
  children,
  onChange,
  name,
  value,
  ...props
}) => {
  return (
    <ModalCard
      alignItems="center"
      aria-checked={checked}
      as="div"
      checked={checked}
      flexDirection="row"
      justifyContent="flex-start"
      p={rem(20)}
      role="checkbox"
      {...props}
    >
      <Checkbox
        alignItems="center"
        disableTabIndex
        // @ts-expect-error not implementing onFocus/onBlur
        field={{
          checked,
          name,
          onChange,
          value,
        }}
        mr={rem(8)}
      />
      <Box as="label">{children}</Box>
    </ModalCard>
  )
}

export default ModalCardCheckbox
