import { Flex } from '@chakra-ui/core'
import { rem } from 'design'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout from '~/components/CancelFlowModals/PanelCancelLayout'
import { PanelSelectAffectedItemsBase } from '~/components/ReportAnIssueModals/PanelSelectAffectedItems'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

const PanelSelectAffectedItems: React.FC<CancelPanelProps> = ({
  panel,
  ...props
}) => {
  let showSelectAll = false
  switch (panel.cancelSaveComponent) {
    case 'Select Affected Items - All':
      showSelectAll = true
      break
    case 'Select Affected Items - Base':
      showSelectAll = false
      break
    default:
      throw new Error(
        `Unexpected Save Component provided to SelectAffectedItems, ${panel.cancelSaveComponent}`
      )
  }

  return (
    <PanelLayout ContentWrapper={null}>
      <PanelSelectAffectedItemsBase
        displaySelectAll={showSelectAll}
        instructionSection={
          <Flex
            alignItems="flex-start"
            flex={{ base: '0 0 auto', tablet: 'unset' }}
            flexDirection="column"
            padding={{
              base: `${rem(8)} 0 0`,
              tablet: `${rem(48)} 0 ${rem(8)}`,
            }}
          >
            <ModalHeader pb={rem(8)} variant="bare">
              {panel.headline}
            </ModalHeader>
            <Body>{panel.subhead}</Body>
          </Flex>
        }
        px={{ base: rem(24), tablet: rem(40) }}
        {...props}
      />
    </PanelLayout>
  )
}

export default PanelSelectAffectedItems
