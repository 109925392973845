import { Box, Flex, FlexProps, Grid } from '@chakra-ui/core'
import { rem, styled } from 'design'
import Form from 'design/components/Form/Form'
import ModalCardCheckbox from 'design/components/ModalCardCheckbox/ModalCardCheckbox'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import { BoxItem } from '~/bb-api/schemata'
import { ICancelFlowRoutingProps } from '~/components/CancelFlowModals/constants'
import { IReportAnIssueFlowRoutingProps } from '~/components/ReportAnIssueModals/constants'
import { ReportAnIssueContext } from '~/context/reportAnIssue'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'
import { TEST_ID } from '~/constants/cypress'
import { Modal, ModalBody } from '@butcherbox/freezer'
import ReportAnIssueFooter from './ReportAnIssueFooter'

type IPanelSelectAffectedItems = {
  displaySelectAll: boolean
  instructionSection?: React.ReactNode
  path?:
    | IReportAnIssueFlowRoutingProps['path']
    | ICancelFlowRoutingProps['path']
} & Omit<IReportAnIssueFlowRoutingProps, 'path'> &
  FlexProps

const FORM_ID = 'select-affected-items'

// Reusable form component as named export (e.g. for use in cancel flow)
export const PanelSelectAffectedItemsBase: React.FC<
  Omit<IPanelSelectAffectedItems, 'handleModalClose'>
> = ({ displaySelectAll, navigate, instructionSection, ...props }) => {
  const {
    orderIssue,
    updateOrderIssue,
    affectedOrder,
    setCreditAmount,
  } = React.useContext(ReportAnIssueContext)

  const boxItemOptions: BoxItem[] = React.useMemo(
    () =>
      Array.prototype.concat(
        duplicateItemsByQuantity(affectedOrder.box.items),
        duplicateItemsByQuantity(affectedOrder.box.addons),
        duplicateItemsByQuantity(affectedOrder.extras),
        duplicateItemsByQuantity(affectedOrder.invoiceItems)
      ),
    [affectedOrder]
  )

  const [selectedList, setSelectedList] = React.useState<Boolean[]>(
    Array(boxItemOptions.length).fill(false)
  )

  const isValid = selectedList.includes(true)
  const allSelected = selectedList.every((el) => el === true)

  const toggleItemSelection = (index) => {
    const newList = [...selectedList]
    newList[index] = !newList[index]
    setSelectedList(newList)
  }

  const toggleSelectAll = () => {
    const newSetting = !allSelected
    const newList = selectedList.map(() => newSetting)
    setSelectedList(newList)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!isValid) return

    let finalSelectedList = selectedList

    // for > 4 items, we reship the whole box
    // https://nbox-341.atlassian.net/browse/ENG-1258
    if (selectedList.filter((x) => x === true).length > 4) {
      finalSelectedList = selectedList.map(() => true)
    }

    const affectedSkus = finalSelectedList
      .map((isSelected, idx) => {
        return isSelected ? boxItemOptions[idx]['sku'] : null
      })
      .filter((el) => el != null)

    const creditAmount = finalSelectedList
      .map((isSelected, idx) => {
        return isSelected ? boxItemOptions[idx]['creditAmount'] : 0
      })
      .reduce((acc, val) => acc + val, 0)

    updateOrderIssue({
      ...orderIssue,
      items: affectedSkus,
    })

    setCreditAmount(creditAmount)

    // TODO: Handle other cases
    switch (true) {
      case affectedSkus.length > 4:
        return navigate('/customer-service')
      case affectedSkus.length <= 4:
        return navigate('/how-can-we-fix-this')
    }
  }

  return (
    <>
      <ModalBody>
        <Box flexBasis="60vh" px={rem(9)} {...props}>
          {instructionSection && instructionSection}
          {/* TODO: Render this component within a Formik context */}
          <Form
            alignItems="center"
            d="flex"
            data-cy={TEST_ID.MODAL_SELECT_AFFECTED_ITEMS}
            flexDirection="column"
            id={FORM_ID}
            mx={{ base: rem(-32), tablet: '0' }}
            onSubmit={handleSubmit}
            px={{ base: rem(8), tablet: '0' }}
            title="Select affected items form"
          >
            {displaySelectAll && (
              <Flex
                flex={{ base: '0 0 auto', tablet: 'unset' }}
                flexDirection="column"
                justifyContent="center"
                marginBottom={rem(8)}
                maxWidth="100%"
                width={rem(528)}
              >
                <ModalCardCheckbox
                  checked={allSelected}
                  data-cy={TEST_ID.MODAL_OPTION}
                  fontSize={rem(14)}
                  onChange={toggleSelectAll}
                  value="select-all"
                >
                  Select All
                </ModalCardCheckbox>
                <StyledBreak />
                <Body>Or, select items if only some were affected</Body>
              </Flex>
            )}
            <Grid
              gridGap={rem(16)}
              gridTemplateColumns={{ base: '1fr', tablet: 'repeat(2, 1fr)' }}
              maxWidth={rem(528)}
              pb={{ base: rem(12), tablet: 'unset' }}
              width="100%"
            >
              {boxItemOptions.map(({ sku, description }, idx) => {
                const itemSelected = selectedList[idx] === true

                return (
                  <ModalCardCheckbox
                    checked={itemSelected}
                    data-cy={TEST_ID.MODAL_OPTION}
                    fontSize={rem(14)}
                    key={idx}
                    onChange={() => toggleItemSelection(idx)}
                    value={sku}
                  >
                    {description}
                  </ModalCardCheckbox>
                )
              })}
            </Grid>
          </Form>
        </Box>
      </ModalBody>

      <ReportAnIssueFooter
        error={!isValid ? 'Please select items' : ''}
        formId={FORM_ID}
      />
    </>
  )
}

const StyledBreak = styled.hr`
  height: ${rem(1)};
  width: 100%;
  margin-top: ${rem(15)};
  margin-bottom: ${rem(12)};
`

// Self service flow modal as default export
export default function PanelSelectAffectedItems({
  handleModalClose,
  ...props
}: IPanelSelectAffectedItems) {
  return (
    <Modal onClose={handleModalClose} size="m" title="Report an issue">
      <PanelSelectAffectedItemsBase {...props} />
    </Modal>
  )
}
