import { ButtonPrimary } from 'design/components/Button/Button'
import { TEST_ID } from '~/constants/cypress'
import { ModalFooter } from '@butcherbox/freezer'
import React from 'react'

type ReportAnIssueFooterProps = {
  buttonText?: string
  error?: string
  formId?: string
  isDisabled?: boolean
  isLoading?: boolean
  onClick?: (event: MouseEvent) => void
}

const ReportAnIssueFooter: React.FC<ReportAnIssueFooterProps> = ({
  error,
  formId,
  buttonText = 'Next',
  ...props
}) => (
  <ModalFooter>
    <ButtonPrimary
      data-cy={TEST_ID.MODAL_SUBMIT}
      error={error}
      // @ts-ignore
      form={formId}
      minWidth="10rem"
      type="submit"
      {...props}
    >
      {buttonText}
    </ButtonPrimary>
  </ModalFooter>
)

export default ReportAnIssueFooter
