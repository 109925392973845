import React, { InputHTMLAttributes } from 'react'
import {
  Box,
  BoxProps,
  ControlBox,
  ControlBoxProps,
  Flex,
  FlexProps,
  VisuallyHidden,
} from '@chakra-ui/core'
import { IconCheck } from '@butcherbox/freezer'
import { rem, shortId } from 'design'
import { FieldProps } from 'formik'
import FormErrorMessage from 'design/components/FormErrorMessage/FormErrorMessage'
import {
  BodySmaller,
  ITypographyProps,
} from 'design/components/Typography/Typography'
import theme from 'design/theme'

type CheckboxProps = FlexProps & {
  checkboxStyleProps?: ControlBoxProps
  disableTabIndex?: boolean
  disabled?: boolean
  iconProps?: object
  label?: React.ReactChild
  labelProps?: ITypographyProps
} & Partial<FieldProps>

const VisuallyHiddenInput: React.FC<
  BoxProps & Omit<InputHTMLAttributes<any>, 'height' | 'width' | 'size'>
> = VisuallyHidden

const Checkbox: React.FC<CheckboxProps> = ({
  checkboxStyleProps = {},
  disabled,
  disableTabIndex = false,
  field,
  form,
  iconProps = {},
  id,
  label,
  labelProps,
  ...props
}) => {
  const inputRef = React.useRef(null)
  const error = !!form?.errors[field?.name]
  const internalId = React.useMemo(
    () => id || `checkbox-${field.name}-${shortId()}`,
    [id, field.name]
  )

  const labelId = React.useMemo(() => `label-${field.name}-${shortId()}`, [
    field.name,
  ])

  const inputId = React.useMemo(() => `input-${field.name}-${shortId()}`, [
    field.name,
  ])

  const handleLabelInteraction = React.useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      /**
       * this is a workaround for Safari not behaving when there are interactive elements
       * inside a label other than the input itself; the typical behavior is the interactive
       * element will be triggered INSTEAD of the input... 🤷‍♂️
       */
      if (
        e.type === 'click' ||
        ('key' in e && (e.key === 'Enter' || e.key === ' '))
      ) {
        inputRef.current.click()
      }
    },
    []
  )

  const errorId = `${inputId}-error`
  return (
    <Flex
      alignItems="top"
      cursor="pointer"
      data-what="checkbox"
      verticalAlign="top"
      {...props}
    >
      {/* This is the sibling input, it's visually hidden */}
      <VisuallyHiddenInput
        aria-describedby={!!error ? errorId : ''}
        aria-labelledby={labelId}
        as="input"
        id={inputId}
        ref={inputRef}
        tabIndex={-1}
        type="checkbox"
        value={field.value}
        {...field}
      />
      {/* This is the control box with a check icon as children */}
      <ControlBox
        _checked={{
          backgroundColor: 'bb.spicedCrimson',
          borderColor: 'bb.spicedCrimson',
        }}
        _hover={{ borderColor: 'bb.spicedCrimson' }}
        aria-checked={field.checked}
        aria-invalid={!!error}
        aria-labelledby={labelId}
        borderColor="bb.stone"
        borderWidth="0.0625rem"
        color="white"
        css={{
          '&:focus': {
            outline: '2px solid #5B9DD9',
          },
          '&:hover': {
            borderColor: theme.colors.bb.spicedCrimson,
          },
        }}
        cursor="pointer"
        id={internalId}
        onClickCapture={handleLabelInteraction}
        onKeyDownCapture={handleLabelInteraction}
        opacity={disabled ? 0.32 : 1}
        role="checkbox"
        rounded="none"
        size={rem(16)}
        tabIndex={disableTabIndex ? -1 : 0}
        {...checkboxStyleProps}
      >
        <Flex
          align="center"
          as="span"
          bg="bb.spicedCrimson"
          justify="center"
          rounded="none"
          size={rem(16)}
        >
          <Box style={{ margin: rem(2) }}>
            <IconCheck
              customColor={{ base: 'white' }}
              size="scale"
              {...iconProps}
            />
          </Box>
        </Flex>
      </ControlBox>

      <BodySmaller
        cursor="pointer"
        ml={rem(9)}
        {...labelProps}
        aria-hidden
        as="label"
        // @ts-ignore
        htmlFor={inputId}
        id={labelId}
      >
        {label}
      </BodySmaller>

      {!!error && (
        <FormErrorMessage
          data-what="checkbox-error"
          hasError={!!error}
          id={errorId}
          mt={rem(4)}
          pb={rem(4)}
          pos="absolute"
          textAlign="left"
          zIndex={1}
        >
          {error}
        </FormErrorMessage>
      )}
    </Flex>
  )
}

export default Checkbox
